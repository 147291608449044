@import '~Styles/colors.module';
$transition-type: cubic-bezier(0.550, 0.055, 0.675, 0.190);

.loader_top_container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .background_loader_container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    color: white;
    left: 0;
    top: 0;

    .background_container {
      height: 100vh;
      flex: 1;   

      .background {
        position: absolute;
        overflow: hidden;
        height: 100vh;
        width: 100vw;
        left: 0;
        top: 0;
        opacity: 0.3;
        background: $color-dark;
      
        transform: scale(1.1);
      }
    }

    .percentage_text {
      font-size: 50px;
      margin-right: 30px;
      z-index: 99;
    }

    .loading_text_container {
      // width: 7vw;
      height: 100vh;
      z-index: 99;
      overflow: hidden;
      background: black;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .loading_text {
        font-size: 40px;
        padding-right: 18px;  
      }
    }
  }
}
